<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        size="lg"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="6">
                        <treeSelectWithService
                            ref="productCategoryTreeSelect"
                            v-model="filterModal.category"
                            :async-search="false"
                            :option="defaultCategory"
                            :multiple="false"
                            :value-consists-of="valueConsistsOf"
                            :service="$store.getters['auth/isBusinessUnit'] ? 'product-categories-client' : 'product-categories'"
                            :disable-branch-nodes="true"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
                            :label="i18nProductCategory"
                            :required="true"
                            :select-class="validationClass($v.filterModal.category)"
                            :parameters="parameters"
                        />
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChain"
                            label-for="filter-chain"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-chain'"
                                ref="chainMultiselect"
                                v-model="filterModal.chains"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChannel"
                            label-for="filter-channel"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-channel'"
                                ref="channelMultiselect"
                                v-model="filterModal.channels"
                                :service="'pos-channels'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRegional"
                            label-for="filter-regional"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-regional'"
                                ref="regionalMultiselect"
                                v-model="filterModal.regionals"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nBrand"
                            label-for="filter-brand"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-brand'"
                                ref="brandUnitMultiselect"
                                v-model="filterModal.brands"
                                :service="'brands'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import dueDateTypes from '@src/mixins/dueDateTypes'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import treeSelectWithService from '@src/components/treeSelectWithService'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        SaveButton,
        ClearFilterButton,
        multiSelectWithService,
        treeSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        dueDateTypes,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            filterModal: {},
            submitLoading: false,
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            defaultCategory: { id: null, name: '', parent_id: null },
            valueConsistsOf: 'LEAF_PRIORITY',
        }
    },
    validations: {
        filterModal: {
            category: {
                required,
            },
        }
    },
    computed: {
        i18nBrand() {
            return this.getI18n('BRANDS', 'TITLES.brand')
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nChannel() {
            return this.getI18n('POS', 'TITLES.channel')
        },
        i18nProductCategory() {
            return this.getI18n('PRODUCT_CATEGORIES', 'TITLES.product_category')
        },
    },
    methods: {
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                category: null,
                chains: [],
                channels: [],
                regionals: [],
                business_units: [],
                brands: [],
            })
            this.resetValidation()
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            this.$v.filterModal.$touch()
            if (!this.$v.filterModal.$invalid && !this.submitLoading) {
                this.submitLoading = true;
                this.$props.handleSubmit(this.filterModal);
                this.$refs.formModal.hide()
            }
        },
        async handleClearFilter() {
            this.cleanModal()
        },
    },
}
</script>
