<template>
    <div class="card card-pdv">
        <PageHeader :title="i18nAmountMonthlyEvolution" />
        <div class="card-body" style="height: 300px;">
            <stacked-column-full-chart
                :series-prop="series"
                :categories-series-prop="categories"
                :busy="loading"
            />
        </div>
    </div>
</template>

<script>
import ShareService from '@src/services/dashboards/widgets/ShareService'

import PageHeader from '@/src/components/PageHeader.vue'
import StackedColumnFullChart from '@/src/components/charts/StackedColumnFullChart.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        StackedColumnFullChart,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            loading: false,
            series: [],
            categories: [],
        }
    },
    validations: {},
    computed: {
        i18nAmountMonthlyEvolution() {
            return `${this.getI18n(i18nKey, 'TITLES.amount')} - ${this.getI18n(i18nKey, 'TITLES.monthly_evolution')}`
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.series = []
            this.categories = []

            ShareService.statistic(parameters)
                .then((response) => {
                    response.data.data.forEach(share => {
                        if (share.data.length > 0) {
                            this.categories.push(this.getI18n('FREQUENCIES', `MONTH_${share.month.toUpperCase()}`))
                            share.data.forEach(item => {
                                const index = this.series.findIndex(find => find.brand_name === item.brand_name);
                                if (index < 0) {
                                    this.series.push({
                                        name: item.brand_name,
                                        data: [item.number],
                                    })
                                } else {
                                    this.series[index].data.push(item.number)
                                }
                            })
                        }
                    });

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
