<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="4">
                            <treeSelectWithService
                                ref="productCategoryTreeSelect"
                                v-model="filters.category"
                                :async-search="false"
                                :option="defaultCategory"
                                :multiple="false"
                                :value-consists-of="valueConsistsOf"
                                :service="$store.getters['auth/isBusinessUnit'] ? 'product-categories-client' : 'product-categories'"
                                :disable-branch-nodes="true"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
                                :label="i18nProductCategory"
                                :disabled="loading"
                                :required="true"
                                :select-class="validationClass($v.filters.category)"
                                :parameters="filterParameters"
                            />
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                                :label="i18nChain"
                                class="label-pdv"
                                label-for="filter-chain"
                            >
                                <multiSelectWithService
                                    :id="'filter-chain'"
                                    ref="chainMultiselect"
                                    v-model="filters.chains"
                                    :service="'pos-chains'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                                :label="i18nChannel"
                                class="label-pdv"
                                label-for="filter-channel"
                            >
                                <multiSelectWithService
                                    :id="'filter-channel'"
                                    ref="channelMultiselect"
                                    v-model="filters.channels"
                                    :service="'pos-channels'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>
            <div v-show="loaded">
                <share-chart ref="shareChart" />
                <share-type-chart ref="shareTypeChart" />
                <div class="card card-pdv">
                    <PageHeader :title="i18nShareChain" />
                    <div class="card-body">
                        <api-mode-vuetable
                            v-if="loaded"
                            ref="vuetableChain"
                            :fetch-data="fetchShareChain"
                            :fields="fieldsChain"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters"
                            :sort-order="sortOrderChain">
                        </api-mode-vuetable>
                    </div>
                </div>
                <div class="card card-pdv">
                    <PageHeader :title="i18nSharePos" />
                    <div class="card-body">
                        <api-mode-vuetable
                            v-if="loaded"
                            ref="vuetablePos"
                            :fetch-data="fetchSharePos"
                            :fields="fieldsPos"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters"
                            :sort-order="sortOrderPos">
                        </api-mode-vuetable>
                    </div>
                </div>
            </div>
            <div v-show="!loaded">
                <no-filter-applied />
            </div>
        </div>
        <share-filter-modal ref="shareFilterModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import ShareService from '@src/services/dashboards/widgets/ShareService'

import { required } from 'vuelidate/lib/validators'
import Layout from '@layouts/main'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import treeSelectWithService from '@src/components/treeSelectWithService'
import shareChart from '@src/router/views/dashboards/share/shareChart.vue'
import shareTypeChart from '@src/router/views/dashboards/share/shareTypeChart.vue'
import shareFilterModal from '@src/router/views/dashboards/share/shareFilterModal.vue'
import NoFilterApplied from '@src/components/NoFilterApplied.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        ApiModeVuetable,
        PageHeader,
        Breadcrumb,
        MoreFiltersLabelButton,
        FilterButton,
        multiSelectWithService,
        treeSelectWithService,
        shareChart,
        shareTypeChart,
        shareFilterModal,
        NoFilterApplied,
    },
    mixins: [
        filterVuetable,
        validationMixin,
        vuetableFormatters,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loaded: false,
            loading: false,
            perPage: 10,
            additionalParameters: {},
            sortOrderChain: [
                {
                    field: 'brand_name',
                    direction: 'asc',
                },
            ],
            sortOrderPos: [
                {
                    field: 'brand_name',
                    direction: 'asc',
                },
            ],
            filterParameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                category: null,
                chains: [],
                channels: [],
                regionals: [],
                business_units: [],
                brands: [],
            },
            defaultCategory: { id: null, name: '', parent_id: null },
            valueConsistsOf: 'LEAF_PRIORITY',
            fieldsChain: [
                {
                    name: 'brand_name',
                    title: this.getI18n('BRANDS', 'TITLES.brand'),
                    sortField: 'brand_name',
                },
                {
                    name: 'chain_name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                    sortField: 'chain_name',
                },
                {
                    name: 'number',
                    title: this.getI18n(i18nKey, 'TITLES.number_of_front'),
                    sortField: 'number',
                },
                {
                    name: 'percentage',
                    title: this.getI18n(i18nKey, 'TITLES.share'),
                    sortField: 'percentage',
                    formatter: this.formatPercentageInteger,
                },
            ],
            fieldsPos: [
                {
                    name: 'brand_name',
                    title: this.getI18n('BRANDS', 'TITLES.brand'),
                    sortField: 'brand_name',
                },
                {
                    name: 'pos_name',
                    title: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TITLES.pos_alias'),
                    sortField: 'pos_name',
                },
                {
                    name: 'long_name',
                    title: this.getI18n(i18nCommon, 'address'),
                    sortField: 'long_name',
                },
                {
                    name: 'regional_name',
                    title: this.getI18n('POS', 'TITLES.regional'),
                    sortField: 'regional_name',
                },
                {
                    name: 'user_name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    sortField: 'user_name',
                },
                {
                    name: 'number',
                    title: this.getI18n(i18nKey, 'TITLES.number_of_front'),
                    sortField: 'number',
                },
                {
                    name: 'percentage',
                    title: this.getI18n(i18nKey, 'TITLES.share'),
                    sortField: 'percentage',
                    formatter: this.formatPercentageInteger,
                },
            ],
        }
    },
    validations: {
        filters: {
            category: {
                required,
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.share_dashboard'),
                    active: true,
                },
            ]
        },
        i18nProductCategory() {
            return this.getI18n('PRODUCT_CATEGORIES', 'TITLES.product_category')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nChannel() {
            return this.getI18n('POS', 'TITLES.channel')
        },
        i18nShareChain() {
            return `${this.getI18n(i18nKey, 'TITLES.share')} ${this.getI18n('POS', 'TITLES.chain')}`
        },
        i18nSharePos() {
            return `${this.getI18n(i18nKey, 'TITLES.share')} ${this.getI18n('POS', 'TITLES.pos')}`
        },
    },
    mounted() {
        this.resetValidation()
    },
    methods: {
        createResumeParameters() {
            return this.formatParameters({
                product_category_id: this.filters.category,
                chain_id: this.filters.chains.map((chain) => chain.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                brand_id: this.filters.brands.map((brand) => brand.id),
            })
        },
        filter(appliedFilters) {
            this.$v.filters.$touch()
            if (appliedFilters || (!this.$v.filters.$invalid && !this.loading)) {
                if (appliedFilters) {
                    this.filters = appliedFilters
                }
                this.additionalParameters = this.createResumeParameters()
                this.refresh()
            }
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.shareFilterModal.showModal(this.filters))
        },
        fetchShareChain(url, params) {
            return ShareService.fetchVuetable('listChain', params)
        },
        fetchSharePos(url, params) {
            return ShareService.fetchVuetable('listPos', params)
        },
        refresh() {
            this.loaded = false
            this.loading = true

            this.$refs.shareChart.refresh(this.additionalParameters)
            this.$refs.shareTypeChart.refresh(this.additionalParameters)
            this.refreshTableChain()
            this.refreshTablePos()

            this.loading = false
            this.loaded = true
        },
        refreshTableChain() {
            this.$nextTick(() => this.$refs.vuetableChain.refresh())
        },
        refreshTablePos() {
            this.$nextTick(() => this.$refs.vuetablePos.refresh())
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },
    }
}
</script>
