import BaseService from '@src/services/BaseService'

class ShareService extends BaseService {
    constructor() {
        super('dashboards/widgets/share')
    }

    statistic(params) {
        return this.axios.post(`${this.getUrl()}/statistic`, params)
    }

    statisticType(params) {
        return this.axios.post(`${this.getUrl()}/statistic-type`, params)
    }
}

export default new ShareService()

